#root {
    display:flex;
    align-items: center;
    justify-content: center;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgb(33, 126, 33), rgb(32, 109, 32), rgb(3, 83, 3));
    width: 400px;
    height: 400px;
    max-height: 400px;
    margin-top: 100px;
  }
