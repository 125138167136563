.TryAgainContainer {
    justify-content: space-around;
    max-height: 400px;
}

.TryAgainButton {
    font-family: Arial,Helvetica,FreeSans,"Liberation Sans","Nimbus Sans L",sans-serif;
    font-size: 2.5em;
    font-weight: 700;
    color: rgb(245,245,245);
    text-shadow: 0 -1px rgba(0,0,0,.1);
    padding: .1em 1em;
    outline: none;
    border: none;
    border-radius: 3px;
    background: #9c0c0c linear-gradient(#912727, #cf1919);
    box-shadow: inset #920505 0 -1px 1px, inset 0 1px 1px #750710, #610b0b 0 0 0 1px, rgba(0,0,0,.3) 0 2px 5px;
    -webkit-animation: pulsate 1.2s linear infinite;
    animation: pulsate 1.2s linear infinite;
    cursor: url('./images/strawberry.png'), pointer;
}

.TryAgainButton:hover {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}
.TryAgainButton:active {
    top: 1px;
    color: #fff;
    text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff;
    box-shadow: 0 -1px 3px rgba(0,0,0,.3), 0 1px 1px #fff, inset 0 1px 2px rgba(0,0,0,.8), inset 0 -1px 0 rgba(0,0,0,.05);
}

@keyframes pulsate {
    50% {color: #fff; text-shadow: 0 -1px rgba(0,0,0,.3), 0 0 5px #ffd, 0 0 8px #fff;}
}
.GameOver {
    color: #fff;
    margin: 0;
    font-family: 'Righteous', cursive;
    font-size: 47px;
    text-shadow: 2px 2px 0  rgb(17, 179, 30);
}

.Copyright {
    font-size: 15px;
    color: #fff;
}

.Results {
    width: 140px;
    font-size: 17px;
    color: #fff;
}

.Link {
    font-size: 15px;
    color: #fff;
}
